.login-container {
  max-width: 400px;
  margin: 50px auto;
  text-align: center;
  background-image: url('https://img.freepik.com/premium-photo/old-paper-texture-background_118047-1365.jpg?w=2000');
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.login-container strong:hover {
  color: #B71C1C;
  transition: color 0.3s;
}

.login-form {
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
}

.login-input {
  padding: 10px;
  margin-bottom: 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-button {
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  background-color: #D32F2F;
  color: #fff;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: #B71C1C;
}

.error-message {
  color: #D32F2F;
  font-weight: bold;
}

