.calendar-container {
  max-width: 800px;
  margin: 20px auto;
  text-align: center;
  background-image: url('https://img.freepik.com/premium-photo/old-paper-texture-background_118047-1365.jpg?w=2000');
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.calendar-container strong:hover {
  color: #B71C1C;
  transition: color 0.3s;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  margin-bottom: 20px;
}

.calendar-header h2 {
  font-size: 42px;
}

.calendar-header h2:hover {
  color: #B71C1C;
  transition: color 0.3s;
}

.logout-button {
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  background-color: #B71C1C;
  color: #fff;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: #D32F2F;
}

.calendar-grid {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.calendar-cell {
  width: 14.28%;
  padding-bottom: 14.28%;
  position: relative;
  margin-bottom: 1%;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-blend-mode: color-burn;
}

.calendar-cell span {
  width: 100%;
  height: 100%;
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 32px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  transition: font-size ease-in 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.available-color,.available-img {
  background-color: #B71C1C;
  font-weight: bolder;
  color: white;
  transition: background-color 0.3s;

  cursor: pointer;
}

.available-img:hover {
  background-color: transparent;
}

.available-color:hover {
  background-color: #D32F2F;
}

.calendar-cell span:hover {
  font-size: 60px;
}
.unavailable {
  background-color: #9e9e9e;
  color: white;
  cursor: not-allowed;
}