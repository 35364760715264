
body {
  background-color: #494949;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  user-select: none;
}
  
  h2, h3 {
    margin: 0;
  }
  
  button {
    font-family: inherit;
  }