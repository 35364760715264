.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('https://img.freepik.com/premium-photo/old-paper-texture-background_118047-1365.jpg?w=2000');
  background-size: cover;
  background-position: center;
  width: 80%;
  max-width: 800px;
  padding: 16px;
  position: relative;
  text-align: center;
  border-radius: 15px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
}

.modal-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.modal-text {
  margin-top: 16px;
  font-size: 12px;
  color: #333;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-bon {
  padding: 8px;
  display: flex;
  flex-direction: column;
  outline: 3px dashed #D32F2F;
  width: 240px;
  height: 96px;
  background-color: #cfbc86;
}

.modal-bon-text {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 60%;
}

.modal-bon-text h4 {
  margin: 0;
}

.vote-section {
  text-align: center;
}

.vote-section p {
  font-size: 15px;
  margin-bottom: 8px;
}

.vote-section form {
  display: flex;
  justify-content: center;
}

.vote-option {
  margin: 0 8px;
  position: relative;
}

.vote-option input[type="radio"] {
  display: none;
}

.vote-option label {
  font-size: 28px;
  cursor: pointer;
  opacity: 0.3;
  transition: opacity 0.2s ease-in-out;
}

.vote-option input[type="radio"]:checked + label {
  opacity: 1;
}

.close-button {
  margin-top: 16px;
  padding: 8px;
  font-size: 14px;
  cursor: pointer;
  background-color: #388E3C;
  color: #fff;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.close-button:hover {
  background-color: #2E7D32;
}